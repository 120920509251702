<template>
  <div id="FlightDelayReport" class="Content-Page">
    <div id="namePage" class="wrap-Main">
      <div class="box-S4">
        <div class="N-Page T-size-36">Daily Report (Revenue)</div>
      </div>
    </div>
    <div id="filltertReport" class="wrap-Main">
      <div class="box-S4">
        <div class="N-Page T-size-24">Filter</div>
      </div>

      <div class="box-S4">
        <div class="B-carddetail flex-between-center">
          <div class="box-S3 noPadding">
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="0"
              :nudge-top="0"
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  class="hideMessage input-date"
                  append-icon="mdi-calendar-range"
                  v-model="picker"
                  label="Select Date"
                  readonly
                  dense
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="picker"
                type="month"
                @change="Selectmonth(picker)"
                @input="menu = false"
              ></v-date-picker>
            </v-menu>
          </div>
          <div class="box-S3 noPadding">
            <v-select
              class="hideMessage"
              :items="itemsListCustomer"
              v-model="selectowner"
              item-text="o_name"
              item-value="o_id"
              label="Customer"
              return-object
              @change="SelectCustomer(selectowner)"
            ></v-select>
          </div>
          <div class="box-S3 noPadding">
            <v-select
              class="hideMessage"
              :items="itemsListDeparture"
              v-model="selectDeparture"
              item-text="ap_name"
              item-value="ap_id"
              label="Departure From"
              return-object
              @change="departureFromChange"
            ></v-select>
          </div>
        </div>
      </div>
    </div>
    <div id="tableData" class="wrap-Main">
      <div class="box-S4">
        <v-data-table :headers="headers" :items="data">
          <!-- <template v-slot:item.ac_seat_layout_url="{ item }">
            <img class="B-img-airport" :src="item.ac_seat_layout_url" />
          </template> -->
          <template v-slot:item.f_id="{ item }" class="text-xs-right">
            <div class="b-action">
              <v-btn class="theme-btn-even" @click="createpdf(item.f_id)">
                <span>VIEW</span>
                <span class="I-arrrowR"></span>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>


<script>
import feathersClientUOA from "../plugins/feathers-client-uoa";
import {
  startOfDay,
  endOfDay,
  startOfMonth,
  endOfMonth,
  lastDayOfYear,
  format,
  getDate,
} from "date-fns";
import moment from "moment";
import { faIR } from "date-fns/locale";
let header = [];
let pdftable = [];
let pdffuel = [];
let data = [];
export default {
  data: () => ({
    headers: [
      { value: "date", text: "Date", sortable: true },
      { value: "ac_name", text: "Aircraft Name", sortable: true },
      { value: "f_flight_no", text: "Flight No.", sortable: true },
      { value: "flt_time", text: "Flight Time", sortable: true },
      // { value: "ac_seat_layout_url", text: "Seat Layout", sortable: true },
      { value: "totalfuel", text: "Total Fuel", sortable: true },
      { value: "location", text: "Path", sortable: true },
      { value: "f_id", text: "", sortable: false },
    ],
    items: [],
    total: 0,
    loading: false,
    dialog: false,
    formModel: {},
    type: "month",
    picker: {},
    dateSelect: new Date().toISOString().substr(0, 7),
    menu: false,
    // itemsListCompany: [],
    //Edit
    itemsListCustomer: [],
    // selectcompany:"",
    selectowner: "",
    ArrListDelayReport: [],
    DataDelayReport: [],
    //Add
    userdata: null,
    data,
    header,
    pdftable,
    totaltime: "",
    totaltimePart: "",
    pdffuel,
    totalfuel: 0,
    c_name: "",
    itemsListDeparture: [],
    selectDeparture: {},
  }),
  computed: {},
  async mounted() {
    //Add
    this.userdata = this.$CurrentUser.GetCurrentUser();
    this.picker = this.dateSelect;
    // this.SelectListCompany();
    await this.renderListDeparture();
    await this.SelectListOwner();
    await this.Listsummarydata();
  },

  methods: {
    async SelectListOwner() {
      try {
        const q = {};
        //Add
        if (this.userdata.r_id == 2) {
          q.o_id = this.userdata.o_id;
        }
        else
        {
          q.o_id = { $ne: 1}
        }
        var res = await feathersClientUOA
          .service("customer")
          .find({ query: q });
        this.itemsListCustomer = res.data;
        this.selectowner = {
          o_id: res.data[0].o_id,
          o_name: res.data[0].o_name,
        };
      } catch (error) {
        console.log(error);
        alert("ไม่สามารถขอข้อมูลจาก server ได้");
      }
    },
    async Listsummarydata() {
      this.data = [];
      try {
        const q = {};
        q.month = this.picker.substr(5, 2);
        q.year = this.picker.substr(0, 4);
        if (this.userdata.r_id == "2") q.customerid = this.userdata.o_id;
        else q.customerid = this.selectowner.o_id;
        q.ap_id = this.selectDeparture.ap_id;
        var res = await feathersClientUOA
          .service("summarydailyreport")
          .find({ query: q });
          this.data= res
      } catch (error) {
        console.log(error);
      }
    },
    pad(d) {
      return d < 10 ? "0" + d.toString() : d.toString();
    },
    async createpdf(fid) {
      let base = "";
      let copyFrom = "";
      let flightReportNo = "";

      if (this.selectDeparture.ap_name == "VTSH") {
        base = "Songkhla Base";
        // copyFrom = "1 copy UOA Songkhla";
        // flightReportNo  = "UOASKL"
        //Edit 20220404
        copyFrom = "1 copy UOA Commercial";
        flightReportNo = "VTSH " + fid;
      } else if (this.selectDeparture.ap_name == "VTBU") {
        base = "U-Tapao Base";
        // copyFrom = "1 copy UOA Songkhla";
        //flightReportNo = "UOAUTP"
        //Edit 20220404
        //copyFrom = "1 copy UOA Commercial";
        flightReportNo = "VTBU " + fid;
      }

      //Add FH_Basis
      let FH_Basis = "";
      this.header = [];
      this.pdftable = [];
      this.pdffuel = [];
      var fixlogo = 0;
      const q = {};
      q.flightid = fid;
      try {
        var res = await feathersClientUOA
          .service("dailyreport")
          .find({ query: q });
        this.c_name = res[0].o_name;
        if (res[0].c_sap_cal_flight_hour != null) {
          FH_Basis = res[0].c_sap_cal_flight_hour.toUpperCase();
          if (FH_Basis == "BLOCKTIME") {
            FH_Basis = "BLOCK TIME";
          }
        }

        this.header.push({
          flightno: res[0].f_flight_no,
          aircarftname: res[0].ac_name,
          companyname: this.c_name,
          date: res[0].Date1,
          month: res[0].Month1,
          year: res[0].year1,
          pilotname: res[0].f_pilot_name,
          copilotnamme: res[0].f_copilot_name,
        });
        const fuel = {};
        fuel.flightid = fid;
        var getfuel = await feathersClientUOA
          .service("getfuelbyflightid")
          .find({ query: q });
        for (var i = 0; i < 10; i = i + 1) {
          if (i < res.length && res[0].fld_id != null) {
            this.pdftable.push({
              start: res[i].location_start,
              end: res[i].location_end,
              availablepayload: res[i].fld_available_payload,
              usepayload: res[i].fld_actual_payload,
              usepax: res[i].fld_actual_pax,
              timestart: res[i].start.substring(11, 16),
              timeend: res[i].end1.substring(11, 16),
              timediff:
                this.pad(parseInt(res[i].diff / 60)) +
                ":" +
                this.pad(res[i].diff % 60),
              take_off: res[i].fld_start_engine.substring(11, 16),
              land: res[i].fld_stop_engine.substring(11, 16),
            });
          } else {
            this.pdftable.push({
              start: " ",
              end: " ",
              availablepayload: null,
              usepayload: null,
              timestart: null,
              timeend: null,
              timediff: null,
              take_off: null,
              land: null,
            });
          }
        }
        for (var i = 0; i < 4; i = i + 1) {
          if (i < getfuel.length) {
            this.pdffuel.push({
              place: getfuel[i].fus_name,
              value: getfuel[i].fu_value,
              unit: "Lts.",
            });
          } else {
            this.pdffuel.push({
              place: " ",
              value: " ",
              unit: " ",
            });
          }
        }
        if (getfuel.length > 0) {
          this.totalfuel = getfuel[0].total;
        } else {
          this.totalfuel = null;
        }
        this.totaltime = res[0].sumdiff;
        this.totaltimePart = res[0].time_part;

        var uoaimage = await this.$LogoService.GetLogoBaseurl64(1);
        var customerlogo = await this.$LogoService.GetLogoBaseurl64(
          this.selectowner.o_id
        );
        if (this.selectowner.o_id == 4) {
          // fixlogo = 20;
          fixlogo = 5;
        }
      } catch (error) {
        console.log(error);
      }

      var pdfMake = require("../../public/js/pdfmake");
      if (pdfMake.vfs == undefined) {
        var pdfFonts = require("../../public/js/vfs_fonts");
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
      }

      let revision = "ISSUE 01 REVISION 00";
      if (this.selectowner.o_id == 4 && this.selectDeparture.ap_id == 1) {
        revision = "ISSUE 01 REVISION 01";
      }else if(this.selectowner.o_id == 4  && this.selectDeparture.ap_id == 2){
        revision = "ISSUE 01 REVISION 03";
      } else {
        revision = "ISSUE 01 REVISION 00";
      }

      var dd = null;
      if (this.selectowner.o_id == 4  && this.selectDeparture.ap_id == 2) {
        dd = {
          content: [
            {
              style: "tableHeader1",
              table: {
                widths: [145, 196, 145],
                body: [
                  [
                    {
                      image: uoaimage.logo,
                      fit: [(uoaimage.w * 50) / 28, (uoaimage.h * 50) / 28],
                      margin: [0, 0, 0, 0],
                      border: [false, false, false, true],
                    },
                    {
                      text: "UOA FLIGHT REPORT",
                      margin: [0, 10, 0, 0],
                      alignment: "center",
                      style: "textheader",
                      border: [false, false, false, true],
                    },
                    {
                      image: customerlogo.logo,
                      fit: [
                        (customerlogo.w * 50) / 28,
                        (customerlogo.h * 50) / 28,
                      ],
                      margin: [-10, fixlogo, 0, 0],
                      alignment: "right",
                      border: [false, false, false, true],
                    },
                  ],
                ],
              },
            },

            {
              style: "tableFlightReportNo",
              table: {
                widths: [122.25, 122.25, 122.25, 122.25],
                body: [
                  [
                    {
                      text: " ",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: " ",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: " ",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: " ",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                  ],
                  [
                    {
                      text: " ",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: " ",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: "Flight Report No.",
                      border: [false, false, false, false],
                      alignment: "right",
                      fillColor: "#C0C0C0",
                    },
                    {
                      text: flightReportNo,
                      border: [false, false, false, false],
                      alignment: "left",
                      fillColor: "#FFFFCC",
                    },
                  ],
                ],
              },
            },

            {
              style: "tableHeader",
              table: {
                widths: [127, 126, 236],
                body: [
                  [
                    {
                      text: base,
                      border: [true, true, true, false],
                      fillColor: "#C0C0C0",
                    },
                    {
                      text: "Flight Date",
                      border: [false, true, false, false],
                      alignment: "center",
                      fillColor: "#C0C0C0",
                    },
                    {
                      text: "Flight No.",
                      border: [true, true, true, false],
                      alignment: "center",
                      fillColor: "#C0C0C0",
                    },
                  ],
                ],
              },
            },
            {
              style: "tableExample",
              table: {
                widths: [127, 36, 36, 36, 236],
                body: [
                  [
                    { text: "AW139", fillColor: "#C0C0C0" },
                    { text: "Date", alignment: "center", fillColor: "#C0C0C0" },
                    {
                      text: "Month",
                      alignment: "center",
                      fillColor: "#C0C0C0",
                    },
                    { text: "Year", alignment: "center", fillColor: "#C0C0C0" },
                    {
                      text: this.header[0].flightno,
                      alignment: "center",
                      fillColor: "#FFFFCC",
                    },
                  ],
                ],
              },
            },
            {
              style: "tableEnd",
              table: {
                widths: [127, 36, 36, 36, 236],
                body: [
                  [
                    {
                      text: this.header[0].aircarftname,
                      border: [true, false, true, true],
                      fillColor: "#FFFFCC",
                    },
                    {
                      text: this.header[0].date.toString().padStart(2, "0"),
                      alignment: "center",
                      border: [true, false, true, true],
                      fillColor: "#FFFFCC",
                    },
                    {
                      text: this.header[0].month.toString().padStart(2, "0"),
                      alignment: "center",
                      border: [true, false, true, true],
                      fillColor: "#FFFFCC",
                    },
                    {
                      text: this.header[0].year,
                      alignment: "center",
                      border: [true, false, true, true],
                      fillColor: "#FFFFCC",
                    },
                    // {
                    //   text: FH_Basis,
                    //   alignment: "center",
                    //   border: [false, false, true, true],
                    //   fillColor: "#FFFFCC",
                    // },
                    //Edit 20220404
                    {
                      text: "",
                      border: [true, false, false, false],
                    },
                  ],
                ],
              },
            },
            {
              style: "tableEnd",
              table: {
                widths: [195, 55, 239],

                body: [
                  [
                    { text: "Customer", border: [true, true, true, false] },
                    { text: "PIC", alignment: "center", fillColor: "#C0C0C0" },
                    {
                      text: this.header[0].pilotname,

                      fillColor: "#FFFFCC",
                    },
                  ],
                  [
                    {
                      text: this.header[0].companyname,
                      border: [true, false, true, true],
                    },
                    { text: "COP", alignment: "center", fillColor: "#C0C0C0" },
                    { text: this.header[0].copilotnamme, fillColor: "#FFFFCC" },
                  ],
                ],
              },
            },
            {
              style: "tableExample",
              table: {
                widths: [67, 50, 41, 35, 30, 35, 30, 42, 42, 55],
                body: [
                  [
                    {
                      text: "Routing",
                      alignment: "center",
                      fillColor: "#C0C0C0",
                      colSpan: 2,
                    },
                    ,
                    {
                      text: "Payload (Kgs)",
                      alignment: "center",
                      fillColor: "#C0C0C0",
                      colSpan: 2,
                    },
                    ,
                    {
                      text: "\nPax",
                      alignment: "center",
                      rowSpan: 2,
                      border: [true, true, true, false],
                      fillColor: "#C0C0C0",
                    },
                    {
                      text: "\nStart",
                      alignment: "center",
                      border: [true, true, true, false],
                      fillColor: "#C0C0C0",
                      rowSpan: 2,
                    },
                    {
                      text: "\nStop",
                      alignment: "center",
                      border: [true, true, true, false],
                      fillColor: "#C0C0C0",
                      rowSpan: 2,
                    },
                    {
                      text: "\nTake off",
                      alignment: "center",
                      border: [true, true, true, false],
                      fillColor: "#C0C0C0",
                      rowSpan: 2,
                    },
                    {
                      text: "\nLand",
                      alignment: "center",
                      border: [true, true, true, false],
                      fillColor: "#C0C0C0",
                      rowSpan: 2,
                    },
                    {
                      text: "\n" + this.data[0].FH_Basic,
                      alignment: "center",
                      border: [true, true, true, false],
                      fillColor: "#C0C0C0",
                      rowSpan: 2,
                    },
                  ],
                  [
                    {
                      text: "From",
                      alignment: "center",
                      border: [true, false, false, false],
                      fillColor: "#C0C0C0",
                    },
                    {
                      text: "To",
                      alignment: "center",
                      border: [true, false, false, false],
                      fillColor: "#C0C0C0",
                    },
                    {
                      text: "Available",
                      alignment: "center",
                      border: [true, false, false, false],
                      fillColor: "#C0C0C0",
                    },
                    {
                      text: "Actual",
                      alignment: "center",
                      border: [true, false, false, false],
                      fillColor: "#C0C0C0",
                    },
                    {
                      text: "Actual",
                      alignment: "center",
                      border: [true, false, false, false],
                    },
                    {
                      text: "Start",
                      alignment: "center",
                      border: [true, false, false, false],
                    },
                    {
                      text: "Stop",
                      alignment: "center",
                      border: [true, false, false, false],
                    },
                    {
                      text: "Flt time",
                      alignment: "center",
                      border: [true, false, false, false],
                    },
                  ],
                ],
              },
            },
            {
              style: "tableExample",
              table: {
                widths: [67, 50, 41, 35, 30, 35, 30, 42, 42, 55],
                body: [
                  [
                    { text: this.pdftable[0].start, alignment: "center" },
                    { text: this.pdftable[0].end, alignment: "center" },
                    {
                      text: this.pdftable[0].availablepayload,
                      alignment: "center",
                    },
                    { text: this.pdftable[0].usepayload, alignment: "center" },
                    { text: this.pdftable[0].usepax, alignment: "center" },
                     { text: this.pdftable[0].take_off, alignment: "center" },
                    { text: this.pdftable[0].land, alignment: "center" },
                    { text: this.pdftable[0].timestart, alignment: "center" },
                    { text: this.pdftable[0].timeend, alignment: "center" },
                    { text: this.pdftable[0].timediff, alignment: "center" },
                  ],
                  [
                    { text: this.pdftable[1].start, alignment: "center" },
                    { text: this.pdftable[1].end, alignment: "center" },
                    {
                      text: this.pdftable[1].availablepayload,
                      alignment: "center",
                    },
                    { text: this.pdftable[1].usepayload, alignment: "center" },
                    { text: this.pdftable[1].usepax, alignment: "center" },
                    { text: this.pdftable[1].take_off, alignment: "center" },
                    { text: this.pdftable[1].land, alignment: "center" },
                    { text: this.pdftable[1].timestart, alignment: "center" },
                    { text: this.pdftable[1].timeend, alignment: "center" },
                    { text: this.pdftable[1].timediff, alignment: "center" },
                  ],
                  [
                    { text: this.pdftable[2].start, alignment: "center" },
                    { text: this.pdftable[2].end, alignment: "center" },
                    {
                      text: this.pdftable[2].availablepayload,
                      alignment: "center",
                    },
                    { text: this.pdftable[2].usepayload, alignment: "center" },
                    { text: this.pdftable[2].usepax, alignment: "center" },
                    { text: this.pdftable[2].take_off, alignment: "center" },
                    { text: this.pdftable[2].land, alignment: "center" },
                    { text: this.pdftable[2].timestart, alignment: "center" },
                    { text: this.pdftable[2].timeend, alignment: "center" },
                    { text: this.pdftable[2].timediff, alignment: "center" },
                  ],
                  [
                    { text: this.pdftable[3].start, alignment: "center" },
                    { text: this.pdftable[3].end, alignment: "center" },
                    {
                      text: this.pdftable[3].availablepayload,
                      alignment: "center",
                    },
                    { text: this.pdftable[3].usepayload, alignment: "center" },
                    { text: this.pdftable[3].usepax, alignment: "center" },
                    { text: this.pdftable[3].take_off, alignment: "center" },
                    { text: this.pdftable[3].land, alignment: "center" },
                    { text: this.pdftable[3].timestart, alignment: "center" },
                    { text: this.pdftable[3].timeend, alignment: "center" },
                    
                    { text: this.pdftable[3].timediff, alignment: "center" },
                  ],
                  [
                    { text: this.pdftable[4].start, alignment: "center" },
                    { text: this.pdftable[4].end, alignment: "center" },
                    {
                      text: this.pdftable[4].availablepayload,
                      alignment: "center",
                    },
                    { text: this.pdftable[4].usepayload, alignment: "center" },
                    { text: this.pdftable[4].usepax, alignment: "center" },
                     { text: this.pdftable[4].take_off, alignment: "center" },
                    { text: this.pdftable[4].land, alignment: "center" },
                    { text: this.pdftable[4].timestart, alignment: "center" },
                    { text: this.pdftable[4].timeend, alignment: "center" },
                   
                    { text: this.pdftable[4].timediff, alignment: "center" },
                  ],
                  [
                    { text: this.pdftable[5].start, alignment: "center" },
                    { text: this.pdftable[5].end, alignment: "center" },
                    {
                      text: this.pdftable[5].availablepayload,
                      alignment: "center",
                    },
                    { text: this.pdftable[5].usepayload, alignment: "center" },
                    { text: this.pdftable[5].usepax, alignment: "center" },
                     { text: this.pdftable[5].take_off, alignment: "center" },
                    { text: this.pdftable[5].land, alignment: "center" },
                    { text: this.pdftable[5].timestart, alignment: "center" },
                    { text: this.pdftable[5].timeend, alignment: "center" },
                   
                    { text: this.pdftable[5].timediff, alignment: "center" },
                  ],
                  [
                    { text: this.pdftable[6].start, alignment: "center" },
                    { text: this.pdftable[6].end, alignment: "center" },
                    {
                      text: this.pdftable[6].availablepayload,
                      alignment: "center",
                    },
                    { text: this.pdftable[6].usepayload, alignment: "center" },
                    { text: this.pdftable[6].usepax, alignment: "center" },
                     { text: this.pdftable[6].take_off, alignment: "center" },
                    { text: this.pdftable[6].land, alignment: "center" },
                    { text: this.pdftable[6].timestart, alignment: "center" },
                    { text: this.pdftable[6].timeend, alignment: "center" },
                   
                    { text: this.pdftable[6].timediff, alignment: "center" },
                  ],
                  [
                    { text: this.pdftable[7].start, alignment: "center" },
                    { text: this.pdftable[7].end, alignment: "center" },
                    {
                      text: this.pdftable[7].availablepayload,
                      alignment: "center",
                    },
                    { text: this.pdftable[7].usepayload, alignment: "center" },
                    { text: this.pdftable[7].usepax, alignment: "center" },
                    { text: this.pdftable[7].take_off, alignment: "center" },
                    { text: this.pdftable[7].land, alignment: "center" },
                    { text: this.pdftable[7].timestart, alignment: "center" },
                    { text: this.pdftable[7].timeend, alignment: "center" },
                    
                    { text: this.pdftable[7].timediff, alignment: "center" },
                  ],
                  [
                    { text: this.pdftable[8].start, alignment: "center" },
                    { text: this.pdftable[8].end, alignment: "center" },
                    {
                      text: this.pdftable[8].availablepayload,
                      alignment: "center",
                    },
                    { text: this.pdftable[8].usepayload, alignment: "center" },
                    { text: this.pdftable[8].usepax, alignment: "center" },
                    { text: this.pdftable[8].take_off, alignment: "center" },
                    { text: this.pdftable[8].land, alignment: "center" },
                    { text: this.pdftable[8].timestart, alignment: "center" },
                    { text: this.pdftable[8].timeend, alignment: "center" },
                    
                    { text: this.pdftable[8].timediff, alignment: "center" },
                  ],
                  [
                    { text: this.pdftable[9].start, alignment: "center" },
                    { text: this.pdftable[9].end, alignment: "center" },
                    {
                      text: this.pdftable[9].availablepayload,
                      alignment: "center",
                    },
                    { text: this.pdftable[9].usepayload, alignment: "center" },
                    { text: this.pdftable[9].usepax, alignment: "center" },
                    { text: this.pdftable[9].take_off, alignment: "center" },
                    { text: this.pdftable[9].land, alignment: "center" },
                    { text: this.pdftable[9].timestart, alignment: "center" },
                    { text: this.pdftable[9].timeend, alignment: "center" },
                    
                    { text: this.pdftable[9].timediff, alignment: "center" },
                  ],
                  [
                    {
                      text: " ",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: " ",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: " ",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: " ",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: " ",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: "\nTOTAL",
                      alignment: "center",
                      fillColor: "#C0C0C0",
                      colSpan: 3,
                      rowSpan: 2,
                    },
                    {
                      text: " ",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: " ",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: "Hrs:Mins",
                      alignment: "center",
                    },
                    { text: this.totaltime, alignment: "center" },
                  ],
                  [
                    {
                      text: " ",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: " ",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: " ",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: " ",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: " ",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: "TOTAL",
                      alignment: "center",
                      fillColor: "#C0C0C0",
                      colSpan: 3,
                    },
                    {
                      text: " ",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: " ",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: "Hrs:Part",
                      alignment: "center",
                    },
                    { text: this.totaltimePart, alignment: "center" },
                  ],
                ],
              },
            },
            {
              style: "tableFuel",
              table: {
                widths: [67, 50, 7, 25, 74, 238],
                body: [
                  [
                    {
                      text: "Refuel",
                      alignment: "center",
                      fillColor: "#C0C0C0",
                      colSpan: 2,
                    },
                    { text: " ", alignment: "center" },
                    {
                      text: " ",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: null,
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: " ",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: " ",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                  ],
                  [
                    {
                      text: "Place",
                      alignment: "center",
                      fillColor: "#C0C0C0",
                    },
                    {
                      text: "Amount",
                      alignment: "center",
                      fillColor: "#C0C0C0",
                    },
                    {
                      text: " ",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: "Sign Operator",
                      rowSpan: 2,
                      colSpan: 2,
                      fillColor: "#C0C0C0",
                    },
                    { text: " " },
                    { text: " ", alignment: "center", rowSpan: 2 },
                  ],
                  [
                    {
                      text: this.pdffuel[0].place,
                      alignment: "center",
                      fontSize: 8.5,
                    },
                    {
                      text: this.pdffuel[0].value + " " + this.pdffuel[0].unit,
                      alignment: "right",
                      border: [true, true, true, true],
                      fontSize: 8.5,
                    },
                    {
                      text: " ",
                      alignment: "right",
                      border: [false, false, false, false],
                    },
                    {
                      text: null,
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    { text: "Sign Operator" },
                    { text: " ", alignment: "center" },
                  ],
                  [
                    {
                      text: this.pdffuel[1].place,
                      alignment: "center",
                      fontSize: 8.5,
                    },
                    {
                      text: this.pdffuel[1].value + " " + this.pdffuel[1].unit,
                      alignment: "right",
                      border: [true, true, true, true],
                      fontSize: 8.5,
                    },
                    {
                      text: " ",
                      alignment: "right",
                      border: [false, false, false, false],
                    },
                    {
                      text: "Sign " + this.header[0].companyname,
                      fillColor: "#C0C0C0",
                      colSpan: 2,
                      border: [true, true, true, false],
                    },
                    {
                      text: "",
                      fillColor: "#C0C0C0",
                      border: [true, false, false, false],
                    },
                    {
                      text: " ",
                      alignment: "center",
                      border: [true, false, true, false],
                    },
                  ],
                  [
                    {
                      text: this.pdffuel[2].place,
                      alignment: "center",
                      fontSize: 8.5,
                    },
                    {
                      text: this.pdffuel[2].value + " " + this.pdffuel[2].unit,
                      alignment: "right",
                      border: [true, true, true, true],
                      fontSize: 8.5,
                    },
                    {
                      text: " ",
                      alignment: "right",
                      border: [false, false, false, false],
                    },
                    {
                      text: "Site Representative",
                      border: [true, false, false, true],
                      fillColor: "#C0C0C0",
                      colSpan: 2,
                    },
                    {
                      text: "",
                      border: [true, false, false, true],
                      fillColor: "#C0C0C0",
                    },
                    {
                      text: "",
                      alignment: "center",
                      border: [true, false, true, true],
                    },
                  ],
                  [
                    {
                      text: this.pdffuel[3].place,
                      alignment: "center",
                      fontSize: 8.5,
                    },
                    {
                      text: this.pdffuel[3].value + " " + this.pdffuel[3].unit,
                      alignment: "right",
                      border: [true, true, true, true],
                      fontSize: 8.5,
                    },
                    {
                      text: " ",
                      alignment: "right",
                      border: [false, false, false, false],
                    },
                    {
                      text: null,
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: " ",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: " ",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                  ],
                  [
                    {
                      text: "Total",
                      alignment: "center",
                      fillColor: "#C0C0C0",
                      fontSize: 8.5,
                    },
                    {
                      text: this.totalfuel + " Lts.",
                      alignment: "right",
                      border: [true, true, true, true],
                      fontSize: 8.5,
                    },
                    {
                      text: " ",
                      alignment: "right",
                      border: [false, false, false, false],
                    },
                    {
                      text: null,
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: " ",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: " ",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                  ],
                ],
              },
            },
            {
              // text: "1 copy " + this.c_name + " (original)",
              text: "",
              style: "footertext",
            },
            {
              text: copyFrom,
            },
            {
              text: "OPS - FM - 105",
              alignment: "right",
              style: "footerdoc",
            },
            {
              text: revision,
              alignment: "right",
            },
          ],
          styles: {
            header: {
              fontSize: 18,
              bold: true,
              margin: [0, 0, 0, 10],
            },
            subheader: {
              fontSize: 16,
              bold: true,
              margin: [0, 10, 0, 5],
            },
            tableExample: {
              margin: [0, 0, 0, 0],
            },
            tableHeader: {
              bold: true,
              fontSize: 13,
              color: "black",
            },
            tableHeader: {
              margin: [0, 15, 0, 0],
            },
            tableHeader1: {
              margin: [10, 35, 0, 0],
            },
            tableEnd: {
              margin: [0, 0, 0, 15],
            },
            tableFuel: {
              margin: [0, 25, 0, 0],
            },
            footertext: {
              margin: [0, 15, 0, 0],
            },
            footerdoc: {
              // margin: [0, 80, 0, 0],
              margin: [0, 50, 0, 0],
            },
            textheader: {
              fontSize: 18,
            },
          },
          defaultStyle: {
            fontSize: 10,
            bold: true,
          },
        };
      } else {
        let rountingStart = "";
        let rountingEnd = "";
        if (this.selectowner.o_id == 2) {
          rountingStart = "\nWheel\nOff";
          rountingEnd = "\nWheel\nOn";
        } else {
          rountingStart = "\nStart";
          rountingEnd = "\nStop";
        }

        //Other Mubadala
        dd = {
          content: [
            {
              style: "tableHeader1",
              table: {
                widths: [145, 196, 145],
                body: [
                  [
                    {
                      image: uoaimage.logo,
                      fit: [(uoaimage.w * 50) / 28, (uoaimage.h * 50) / 28],
                      margin: [0, 0, 0, 0],
                      border: [false, false, false, true],
                    },
                    {
                      text: "UOA FLIGHT REPORT",
                      margin: [0, 10, 0, 0],
                      alignment: "center",
                      style: "textheader",
                      border: [false, false, false, true],
                    },
                    {
                      image: customerlogo.logo,
                      fit: [
                        (customerlogo.w * 50) / 28,
                        (customerlogo.h * 50) / 28,
                      ],
                      margin: [-10, fixlogo, 0, 0],
                      alignment: "right",
                      border: [false, false, false, true],
                    },
                  ],
                ],
              },
            },
            {
              style: "tableFlightReportNo",
              table: {
                widths: [122.25, 122.25, 122.25, 122.25],
                body: [
                  [
                    {
                      text: " ",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: " ",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: " ",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: " ",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                  ],
                  [
                    {
                      text: " ",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: " ",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: "Flight Report No.",
                      border: [false, false, false, false],
                      alignment: "right",
                      fillColor: "#C0C0C0",
                    },
                    {
                      text: flightReportNo,
                      border: [false, false, false, false],
                      alignment: "left",
                      fillColor: "#FFFFCC",
                    },
                  ],
                ],
              },
            },

            {
              style: "tableHeader",
              table: {
                widths: [163, 163, 163],
                body: [
                  [
                    {
                      text: "Songkhla Base",
                      border: [true, true, true, false],
                      fillColor: "#C0C0C0",
                    },
                    {
                      text: "Flight Date",
                      border: [false, true, false, false],
                      alignment: "center",
                      fillColor: "#C0C0C0",
                    },
                    {
                      text: "Flight No.",
                      border: [true, true, true, false],
                      alignment: "center",
                      fillColor: "#C0C0C0",
                    },
                  ],
                ],
              },
            },
            {
              style: "tableExample",
              table: {
                widths: [163, 48, 48, 49, 163],
                body: [
                  [
                    { text: "AW139", fillColor: "#C0C0C0" },
                    { text: "Date", alignment: "center", fillColor: "#C0C0C0" },
                    {
                      text: "Month",
                      alignment: "center",
                      fillColor: "#C0C0C0",
                    },
                    { text: "Year", alignment: "center", fillColor: "#C0C0C0" },
                    {
                      text: this.header[0].flightno,
                      alignment: "center",
                      fillColor: "#FFFFCC",
                    },
                  ],
                ],
              },
            },
            {
              style: "tableEnd",
              table: {
                widths: [163, 48, 48, 49, 163],
                body: [
                  [
                    {
                      text: this.header[0].aircarftname,
                      border: [true, false, true, true],
                      fillColor: "#FFFFCC",
                    },
                    {
                      text: this.header[0].date.toString().padStart(2, "0"),
                      alignment: "center",
                      border: [true, false, true, true],
                      fillColor: "#FFFFCC",
                    },
                    {
                      text: this.header[0].month.toString().padStart(2, "0"),
                      alignment: "center",
                      border: [true, false, true, true],
                      fillColor: "#FFFFCC",
                    },
                    {
                      text: this.header[0].year,
                      alignment: "center",
                      border: [true, false, true, true],
                      fillColor: "#FFFFCC",
                    },
                    {
                      text: "",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                  ],
                ],
              },
            },
            {
              style: "tableEnd",
              table: {
                widths: [244, 45, 200],

                body: [
                  [
                    { text: "Customer", border: [true, true, true, false] },
                    { text: "PIC", alignment: "center", fillColor: "#C0C0C0" },
                    {
                      text: this.header[0].pilotname,

                      fillColor: "#FFFFCC",
                    },
                  ],
                  [
                    {
                      text: this.header[0].companyname,
                      border: [true, false, true, true],
                    },
                    { text: "COP", alignment: "center", fillColor: "#C0C0C0" },
                    { text: this.header[0].copilotnamme, fillColor: "#FFFFCC" },
                  ],
                ],
              },
            },
            {
              style: "tableExample",
              table: {
                widths: [77, 77, 51, 51, 44, 45, 45, 54],
                body: [
                  [
                    {
                      text: "Routing",
                      alignment: "center",
                      fillColor: "#C0C0C0",
                      colSpan: 2,
                    },
                    ,
                    {
                      text: "Payload",
                      alignment: "center",
                      fillColor: "#C0C0C0",
                      colSpan: 2,
                    },
                    ,
                    {
                      text: "\nPax",
                      alignment: "center",
                      rowSpan: 2,
                      border: [true, true, true, false],
                      fillColor: "#C0C0C0",
                    },
                    {
                      text: rountingStart,
                      alignment: "center",
                      border: [true, true, true, false],
                      fillColor: "#C0C0C0",
                      rowSpan: 2,
                    },
                    {
                      text: rountingEnd,
                      alignment: "center",
                      border: [true, true, true, false],
                      fillColor: "#C0C0C0",
                      rowSpan: 2,
                    },
                    {
                      text: "\nFlt time",
                      alignment: "center",
                      border: [true, true, true, false],
                      fillColor: "#C0C0C0",
                      rowSpan: 2,
                    },
                  ],
                  [
                    {
                      text: "From",
                      alignment: "center",
                      border: [true, false, false, false],
                      fillColor: "#C0C0C0",
                    },
                    {
                      text: "To",
                      alignment: "center",
                      border: [true, false, false, false],
                      fillColor: "#C0C0C0",
                    },
                    {
                      text: "Available",
                      alignment: "center",
                      border: [true, false, false, false],
                      fillColor: "#C0C0C0",
                    },
                    {
                      text: "Actual",
                      alignment: "center",
                      border: [true, false, false, false],
                      fillColor: "#C0C0C0",
                    },
                    {
                      text: "Actual",
                      alignment: "center",
                      border: [true, false, false, false],
                    },
                    {
                      text: "Start",
                      alignment: "center",
                      border: [true, false, false, false],
                    },
                    {
                      text: "Stop",
                      alignment: "center",
                      border: [true, false, false, false],
                    },
                    {
                      text: "Flt time",
                      alignment: "center",
                      border: [true, false, false, false],
                    },
                  ],
                ],
              },
            },
            {
              style: "tableExample",
              table: {
                widths: [77, 77, 51, 51, 44, 45, 45, 54],

                body: [
                  [
                    { text: this.pdftable[0].start, alignment: "center" },
                    { text: this.pdftable[0].end, alignment: "center" },
                    {
                      text: this.pdftable[0].availablepayload,
                      alignment: "center",
                    },
                    { text: this.pdftable[0].usepayload, alignment: "center" },
                    { text: this.pdftable[0].usepax, alignment: "center" },
                    { text: this.pdftable[0].timestart, alignment: "center" },
                    { text: this.pdftable[0].timeend, alignment: "center" },
                    { text: this.pdftable[0].timediff, alignment: "center" },
                  ],
                  [
                    { text: this.pdftable[1].start, alignment: "center" },
                    { text: this.pdftable[1].end, alignment: "center" },
                    {
                      text: this.pdftable[1].availablepayload,
                      alignment: "center",
                    },
                    { text: this.pdftable[1].usepayload, alignment: "center" },
                    { text: this.pdftable[1].usepax, alignment: "center" },
                    { text: this.pdftable[1].timestart, alignment: "center" },
                    { text: this.pdftable[1].timeend, alignment: "center" },
                    { text: this.pdftable[1].timediff, alignment: "center" },
                  ],
                  [
                    { text: this.pdftable[2].start, alignment: "center" },
                    { text: this.pdftable[2].end, alignment: "center" },
                    {
                      text: this.pdftable[2].availablepayload,
                      alignment: "center",
                    },
                    { text: this.pdftable[2].usepayload, alignment: "center" },
                    { text: this.pdftable[2].usepax, alignment: "center" },
                    { text: this.pdftable[2].timestart, alignment: "center" },
                    { text: this.pdftable[2].timeend, alignment: "center" },
                    { text: this.pdftable[2].timediff, alignment: "center" },
                  ],
                  [
                    { text: this.pdftable[3].start, alignment: "center" },
                    { text: this.pdftable[3].end, alignment: "center" },
                    {
                      text: this.pdftable[3].availablepayload,
                      alignment: "center",
                    },
                    { text: this.pdftable[3].usepayload, alignment: "center" },
                    { text: this.pdftable[3].usepax, alignment: "center" },
                    { text: this.pdftable[3].timestart, alignment: "center" },
                    { text: this.pdftable[3].timeend, alignment: "center" },
                    { text: this.pdftable[3].timediff, alignment: "center" },
                  ],
                  [
                    { text: this.pdftable[4].start, alignment: "center" },
                    { text: this.pdftable[4].end, alignment: "center" },
                    {
                      text: this.pdftable[4].availablepayload,
                      alignment: "center",
                    },
                    { text: this.pdftable[4].usepayload, alignment: "center" },
                    { text: this.pdftable[4].usepax, alignment: "center" },
                    { text: this.pdftable[4].timestart, alignment: "center" },
                    { text: this.pdftable[4].timeend, alignment: "center" },
                    { text: this.pdftable[4].timediff, alignment: "center" },
                  ],
                  [
                    { text: this.pdftable[5].start, alignment: "center" },
                    { text: this.pdftable[5].end, alignment: "center" },
                    {
                      text: this.pdftable[5].availablepayload,
                      alignment: "center",
                    },
                    { text: this.pdftable[5].usepayload, alignment: "center" },
                    { text: this.pdftable[5].usepax, alignment: "center" },
                    { text: this.pdftable[5].timestart, alignment: "center" },
                    { text: this.pdftable[5].timeend, alignment: "center" },
                    { text: this.pdftable[5].timediff, alignment: "center" },
                  ],
                  [
                    { text: this.pdftable[6].start, alignment: "center" },
                    { text: this.pdftable[6].end, alignment: "center" },
                    {
                      text: this.pdftable[6].availablepayload,
                      alignment: "center",
                    },
                    { text: this.pdftable[6].usepayload, alignment: "center" },
                    { text: this.pdftable[6].usepax, alignment: "center" },
                    { text: this.pdftable[6].timestart, alignment: "center" },
                    { text: this.pdftable[6].timeend, alignment: "center" },
                    { text: this.pdftable[6].timediff, alignment: "center" },
                  ],
                  [
                    { text: this.pdftable[7].start, alignment: "center" },
                    { text: this.pdftable[7].end, alignment: "center" },
                    {
                      text: this.pdftable[7].availablepayload,
                      alignment: "center",
                    },
                    { text: this.pdftable[7].usepayload, alignment: "center" },
                    { text: this.pdftable[7].usepax, alignment: "center" },
                    { text: this.pdftable[7].timestart, alignment: "center" },
                    { text: this.pdftable[7].timeend, alignment: "center" },
                    { text: this.pdftable[7].timediff, alignment: "center" },
                  ],
                  [
                    { text: this.pdftable[8].start, alignment: "center" },
                    { text: this.pdftable[8].end, alignment: "center" },
                    {
                      text: this.pdftable[8].availablepayload,
                      alignment: "center",
                    },
                    { text: this.pdftable[8].usepayload, alignment: "center" },
                    { text: this.pdftable[8].usepax, alignment: "center" },
                    { text: this.pdftable[8].timestart, alignment: "center" },
                    { text: this.pdftable[8].timeend, alignment: "center" },
                    { text: this.pdftable[8].timediff, alignment: "center" },
                  ],
                  [
                    { text: this.pdftable[9].start, alignment: "center" },
                    { text: this.pdftable[9].end, alignment: "center" },
                    {
                      text: this.pdftable[9].availablepayload,
                      alignment: "center",
                    },
                    { text: this.pdftable[9].usepayload, alignment: "center" },
                    { text: this.pdftable[9].usepax, alignment: "center" },
                    { text: this.pdftable[9].timestart, alignment: "center" },
                    { text: this.pdftable[9].timeend, alignment: "center" },
                    { text: this.pdftable[9].timediff, alignment: "center" },
                  ],
                  [
                    {
                      text: " ",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: " ",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: " ",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: " ",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: " ",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: "TOTAL",
                      alignment: "center",
                      fillColor: "#C0C0C0",
                      colSpan: 2,
                    },
                    ,
                    { text: this.totaltime, alignment: "center" },
                  ],
                ],
              },
            },
            {
              style: "tableFuel",
              table: {
                widths: [77, 50, 27, 25, 130, 162],
                body: [
                  [
                    {
                      text: "Refuel",
                      alignment: "center",
                      fillColor: "#C0C0C0",
                      colSpan: 3,
                    },
                    { text: " ", alignment: "center" },
                    "",
                    {
                      text: null,
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: " ",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: " ",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                  ],
                  [
                    {
                      text: "Place",
                      alignment: "center",
                      fillColor: "#C0C0C0",
                    },
                    {
                      text: "Amount",
                      alignment: "center",
                      fillColor: "#C0C0C0",
                      colSpan: 2,
                    },
                    "",
                    {
                      text: null,
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    { text: "Sign Operator", rowSpan: 2, fillColor: "#C0C0C0" },
                    { text: " ", alignment: "center", rowSpan: 2 },
                  ],
                  [
                    { text: this.pdffuel[0].place, alignment: "center" },
                    {
                      text: this.pdffuel[0].value,
                      alignment: "right",
                      border: [true, true, false, true],
                    },
                    {
                      text: this.pdffuel[0].unit,
                      alignment: "right",
                      border: [false, true, true, true],
                    },
                    {
                      text: null,
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    { text: "Sign Operator" },
                    { text: " ", alignment: "center" },
                  ],
                  [
                    { text: this.pdffuel[1].place, alignment: "center" },
                    {
                      text: this.pdffuel[1].value,
                      alignment: "right",
                      border: [true, true, false, true],
                    },
                    {
                      text: this.pdffuel[1].unit,
                      alignment: "right",
                      border: [false, true, true, true],
                    },
                    {
                      text: null,
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: "Sign " + this.header[0].companyname,
                      fillColor: "#C0C0C0",
                      border: [true, false, false, false],
                    },
                    {
                      text: " ",
                      alignment: "center",
                      border: [true, false, true, false],
                    },
                  ],
                  [
                    { text: this.pdffuel[2].place, alignment: "center" },
                    {
                      text: this.pdffuel[2].value,
                      alignment: "right",
                      border: [true, true, false, true],
                    },
                    {
                      text: this.pdffuel[2].unit,
                      alignment: "right",
                      border: [false, true, true, true],
                    },
                    {
                      text: null,
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: "Site Representative",
                      border: [true, false, false, true],
                      fillColor: "#C0C0C0",
                    },
                    {
                      text: "",
                      alignment: "center",
                      border: [true, false, true, true],
                    },
                  ],
                  [
                    { text: this.pdffuel[3].place, alignment: "center" },
                    {
                      text: this.pdffuel[3].value,
                      alignment: "right",
                      border: [true, true, false, true],
                    },
                    {
                      text: this.pdffuel[3].unit,
                      alignment: "right",
                      border: [false, true, true, true],
                    },
                    {
                      text: null,
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: " ",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: " ",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                  ],
                  [
                    {
                      text: "Total",
                      alignment: "center",
                      fillColor: "#C0C0C0",
                    },
                    {
                      text: this.totalfuel,
                      alignment: "right",
                      border: [true, true, false, true],
                    },
                    {
                      text: "Lts.",
                      alignment: "right",
                      border: [false, true, true, true],
                    },
                    {
                      text: null,
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: " ",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                    {
                      text: " ",
                      alignment: "center",
                      border: [false, false, false, false],
                    },
                  ],
                ],
              },
            },
            {
              text: "",
              style: "footertext",
            },
            {
              text: "",
            },
            {
              text: "OPS - FM - 05",
              alignment: "right",
              style: "footerdoc",
            },
            {
              text: revision,
              alignment: "right",
            },
          ],
          styles: {
            header: {
              fontSize: 18,
              bold: true,
              margin: [0, 0, 0, 10],
            },
            subheader: {
              fontSize: 16,
              bold: true,
              margin: [0, 10, 0, 5],
            },
            tableExample: {
              margin: [0, 0, 0, 0],
            },
            tableHeader: {
              bold: true,
              fontSize: 13,
              color: "black",
            },
            tableHeader: {
              margin: [0, 15, 0, 0],
            },
            tableHeader1: {
              margin: [10, 35, 0, 0],
            },
            tableEnd: {
              margin: [0, 0, 0, 15],
            },
            tableFuel: {
              margin: [0, 25, 0, 0],
            },
            footertext: {
              margin: [0, 15, 0, 0],
            },
            footerdoc: {
              // margin: [0, 80, 0, 0],
              margin: [0, 50, 0, 0],
            },
            textheader: {
              fontSize: 18,
            },
          },
          defaultStyle: {
            fontSize: 10,
            bold: true,
          },
        };
      }

      pdfMake.createPdf(dd).open({});
    },
    async SelectCustomer(args) {
      this.selectowner = args;
      await this.Listsummarydata();
    },
    async Selectmonth(picker) {
      this.picker = picker;
      await this.Listsummarydata();
    },
    async renderListDeparture() {
      try {
        var data = await feathersClientUOA.service("mastercommand").find({});
        var airports = data[0].airport.filter((x) => x.ap_checkin == true);
        airports.forEach((element) => {
          this.itemsListDeparture.push(element);
        });
      } catch (error) {
        console.log(error);
        alert("ไม่สามารถขอข้อมูลจาก server ได้");
      }
      await this.selectDepartureFrom();
    },
    async selectDepartureFrom() {
      try {
        let departureChange =
          await this.$LocalStorageService.getDepartureChange();
        if (departureChange == null) {
          this.selectDeparture = {
            ap_id: this.itemsListDeparture[0].ap_id,
            ap_name: this.itemsListDeparture[0].ap_name,
          };
        } else {
          let obj = JSON.parse(departureChange);
          this.selectDeparture = { ap_id: obj.ap_id, ap_name: obj.ap_name };
        }
      } catch (error) {
        console.log(
          "SelectListCustomer ไม่สามารถขอข้อมูลจาก server ได้ /nError : " +
            error
        );
      }
    },
    async departureFromChange(arg) {
      this.$LocalStorageService.setDepartureChange(
        this.selectDeparture.ap_id,
        this.selectDeparture.ap_name
      );
      this.Listsummarydata();
    },
  },
};
</script> 


<style>
</style>